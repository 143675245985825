<template>
  <section class="container mx-auto">
    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename="تقرير نتائج مقياس شركة العُلو للتفكير الحاسوبي"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div class="px-11">
            <div>
              <p
                class="text-primary font-extrabold text-center mb-11 w-2/3 w-fulll mx-auto text-custom"
                style="font-size: 54px"
              >
                تقرير نتائج مقياس شركة العُلو للتفكير الحاسوبي
              </p>
            </div>
            <div class="sm:flex justify-between items-center mx-11">
              <div class="ml-auto info">
                <p
                  v-if="myData && myData.account && myData.account.name"
                  class="font-bold text-2xl mb-2 text-right"
                >
                  {{ myData.account.name }} : الاسم
                </p>
                <p class="text-secondary text-xl text-right">
                  أولاً : النتائج الكمية
                </p>
              </div>
            </div>
            <div
              class="flex flex-row-reverse space-x-reverse space-x-4 mt-6 categories-custom sm:px-0 px-3"
            >
              <div
                v-for="(item, i) in myData.categories"
                :key="i"
                class="card-chart w-1/4 justify-between relative flex items-center text-white text-right mb-6"
              >
                <img
                  :src="items2[i].img"
                  alt=""
                  class="w-20 h-20 px-3 absolute left-0"
                />
                <div class="z-20 flex items-end w-full justify-center">
                  <div class="mx-3" style="">
                    <div class="font-bold">مهارة</div>

                    <div class="px-3 font-bold text-2xl">{{ item.title }}</div>
                  </div>
                  <progress-bar :options="options" :value="item.percentage" />
                </div>
              </div>
            </div>
            <div class="flex justify-between categories-sec-2-big">
              <div
                class="sm:p-6 p-3 text-right categories-sec-2"
                style="
                  box-shadow: 0px 3px 16px #00000029;
                  border-radius: 16px;
                  width: 48%;
                "
              >
                <p class="text-lg font-bold">المقياس ككل</p>
                <p class="text-secondary text-lg my-3">
                  تعبر عن نتيجتك في مقياس التفكير الحاسوبي بشكل اجمالي
                </p>
                <div
                  class="card-chart w-full justify-between mt-6 relative flex items-center text-white text-right mb-6"
                >
                  <img
                    :src="itemSpe[0].img"
                    alt=""
                    class="w-20 h-20 px-3 absolute left-0"
                  />
                  <div class="z-20 flex items-end w-full justify-center">
                    <div class="mx-3" style="">
                      <div class="sm:px-3 px-1 sm:font-bold sm:text-2xl">
                        {{ itemSpe[0].title }}
                      </div>
                      <div class="font-bold">يشمل كل المهارات</div>
                    </div>
                    <progress-bar
                      :options="options"
                      :value="myData.account.total_result"
                    />
                  </div>
                </div>
              </div>
              <div
                class="w-1/2 p-6 categories-sec-2"
                style="box-shadow: 0px 3px 16px #00000029; border-radius: 16px"
              >
                <div v-for="(item, i) in myData.categories" :key="i">
                  <div
                    class="z-20 flex items-center justify-between text-right w-full mb-6"
                  >
                    <div class="w-3/4 bg-gray-200 rounded-full h-2.5">
                      <div
                        class="h-2.5 rounded-full"
                        style="background: #67bb80"
                        :style="{ width: `${item.percentage}%` }"
                      ></div>
                    </div>

                    <div class="mx-3 text-right" style="">
                      <div class="text-secondary font-bold">
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex flex-col mx-auto my-11l text-right lg:px-0 sm:px-6 px-3 mx-11"
              style="margin-top: 100px"
            >
              <div class="font-bold text-primary my-6" style="font-size: 24px">
                ثانيا : النتائج النوعية
              </div>
              <div
                class="py-3"
                style="box-shadow: 0px 3px 16px #00000029; border-radius: 16px"
              >
                <div
                  v-for="(item, i) in myData.categories"
                  :key="i"
                  class="text-secondary flex items-center justify-end mb-6 sm:py-5 py-0"
                  :style="
                    i == 3
                      ? 'border-bottom: 0px'
                      : 'border-bottom: 1px dashed rgb(168 181 195 / 48%); '
                  "
                >
                  <div class="sm:px-6 px-1 sm:flex-row flex flex-col-reverse">
                    <div class="mx-3">
                      <p
                        class="text-black font-bold mb-2"
                        style="font-size: 14px"
                      >
                        مهارة {{ item.title }}
                      </p>
                      <p class="text-secondary" style="font-size: 14px">
                        {{ item.result_text }}
                      </p>
                    </div>
                    <div class="img-style">
                      <img :src="items[i].img" alt="" class="mx-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- PDF Content Here -->
        </section>
      </vue-html2pdf>
    </div>
    <div id="printMe">
      <div>
        <p
          class="text-primary font-extrabold text-center mb-11 w-2/3 w-fulll mx-auto text-custom"
          style="font-size: 54px"
        >
          تقرير نتائج مقياس شركة العُلو للتفكير الحاسوبي
        </p>
      </div>
      <div class="sm:flex justify-between items-center">
        <button @click="generateReport" class="pdf-btn sm:mb-0 mb-6">
          PDF تصدير
        </button>

        <div class="ml-auto info">
          <div
            v-if="myData && myData.account && myData.account.name"
            class="font-bold text-2xl mb-2 text-right flex"
          >
            <div>{{ myData.account.name }}</div>
            <div>: الاسم</div>
          </div>
          <p class="text-secondary text-xl text-right">
            أولاً : النتائج الكمية
          </p>
        </div>
      </div>
      <div
        class="flex flex-row-reverse space-x-reverse space-x-4 mt-6 categories-custom sm:px-0 px-3"
      >
        <div
          v-for="(item, i) in myData.categories"
          :key="i"
          class="card-chart w-1/4 justify-between relative flex items-center text-white text-right mb-6"
        >
          <img
            :src="items2[i].img"
            alt=""
            class="w-20 h-20 px-3 absolute left-0"
          />
          <div class="z-20 flex items-end w-full justify-center">
            <div class="mx-3" style="">
              <div class="font-bold">مهارة</div>

              <div class="px-3 font-bold text-2xl">{{ item.title }}</div>
            </div>
            <progress-bar :options="options" :value="item.percentage" />
          </div>
        </div>
      </div>
      <div class="flex justify-between categories-sec-2-big">
        <div
          class="sm:p-6 p-3 text-right categories-sec-2"
          style="
            box-shadow: 0px 3px 16px #00000029;
            border-radius: 16px;
            width: 48%;
          "
        >
          <p class="text-lg font-bold">المقياس ككل</p>
          <p class="text-secondary text-lg my-3">
            تعبر عن نتيجتك في مقياس التفكير الحاسوبي بشكل اجمالي
          </p>
          <div
            class="card-chart w-full justify-between mt-6 relative flex items-center text-white text-right mb-6"
          >
            <img
              :src="itemSpe[0].img"
              alt=""
              class="w-20 h-20 px-3 absolute left-0"
            />
            <div class="z-20 flex items-end w-full justify-center">
              <div class="mx-3" style="">
                <div class="sm:px-3 px-1 sm:font-bold sm:text-2xl">
                  {{ itemSpe[0].title }}
                </div>
                <div class="font-bold">يشمل كل المهارات</div>
              </div>
              <progress-bar
                :options="options"
                :value="myData.account.total_result"
              />
            </div>
          </div>
        </div>
        <div
          class="w-1/2 p-6 categories-sec-2"
          style="box-shadow: 0px 3px 16px #00000029; border-radius: 16px"
        >
          <div v-for="(item, i) in myData.categories" :key="i">
            <div
              class="z-20 flex items-center justify-between text-right w-full mb-6"
            >
              <div class="w-3/4 bg-gray-200 rounded-full h-2.5">
                <div
                  class="h-2.5 rounded-full"
                  style="background: #67bb80"
                  :style="{ width: `${item.percentage}%` }"
                ></div>
              </div>

              <div class="mx-3 text-right" style="">
                <div class="text-secondary font-bold">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col mx-auto my-11 text-right lg:px-0 sm:px-6 px-3">
        <div class="font-bold text-primary my-6" style="font-size: 24px">
          ثانيا : النتائج النوعية
        </div>
        <div
          class="py-3"
          style="box-shadow: 0px 3px 16px #00000029; border-radius: 16px"
        >
          <div
            v-for="(item, i) in myData.categories"
            :key="i"
            class="text-secondary flex items-center justify-end mb-6 sm:py-5 py-0"
            :style="
              i == 3
                ? 'border-bottom: 0px'
                : 'border-bottom: 1px dashed rgb(168 181 195 / 48%); '
            "
          >
            <div class="sm:px-6 px-1 sm:flex-row flex flex-col-reverse">
              <div class="mx-3">
                <p class="text-black font-bold mb-2" style="font-size: 14px">
                  مهارة {{ item.title }}
                </p>
                <p class="text-secondary" style="font-size: 14px">
                  {{ item.result_text }}
                </p>
              </div>
              <div class="img-style">
                <img :src="items[i].img" alt="" class="mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sm:flex justify-center">
      <button class="custom-btn re-custom my-11 flex items-center">
        <ShareNetwork
          network="Whatsapp"
          :url="shareLink"
          title="تعرف على نتيجتك في مهارات التفكير الحاسوبي مجانا من خلال مقياس العلو التعليمية"
          class="flex items-center"
        >
          <span>شارك من خلال الواتس اب</span>
          <img src="/images/Group 43109.svg" alt="" class="mx-3" />
        </ShareNetwork>
      </button>
      <button class="custom-btn re-custom my-11 flex items-center">
        <ShareNetwork
          network="twitter"
          :url="shareLink"
          title="تعرف على نتيجتك في مهارات التفكير الحاسوبي مجانا من خلال مقياس العلو التعليمية"
          class="flex items-center"
        >
          <span>شارك من خلال تويتر</span>

          <img src="/images/x 4.svg" alt="" class="mx-3" />
        </ShareNetwork>
      </button>
    </div>
  </section>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      show: true,
      shareLink: "",
      items: [
        {
          title: "مهارة الخوارزمية",
          desc: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.",
          img: "images/algorithm.svg",
        },
        {
          title: "مهارة التجريد",
          desc: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.",
          img: "images/Abstraction.svg",
        },
        {
          title: "مهارة التعرف على الانماط",
          desc: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.",
          img: "images/pattern recognition.svg",
        },
        {
          title: "مهارة التقسيم",
          desc: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.",
          img: "images/flow.svg",
        },
        {
          title: "المقياس ككل",
          desc: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.",
          img: "images/indicator 12.svg",
        },
        {
          title: "التوصية",
          desc: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.",
          img: "images/RECOMMENDATION 1.svg",
        },
      ],
      items2: [
        { title: "الخوارزمية", img: "images/Group32.svg" },
        { title: "التجريد", img: "images/Abstraction2.svg" },
        { title: "التعرف على الانماط", img: "images/pattern recognition2.svg" },
        { title: "التقسيم", img: "images/flow2.svg" },
      ],
      itemSpe: [{ title: "المقياس ككل", img: "images/indicator 122.svg" }],
      items3: [
        { title: "الخوارزمية", pres: "20" },
        { title: "التجريد", pres: "40" },
        { title: "التعرف على الانماط", pres: "50" },
        { title: "التقسيم", pres: "90" },
      ],
      options: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 15,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#67BB80",
          backgroundColor: "#919EAB",
          inverted: false,
        },
        layout: {
          height: 54,
          width: 40,
          verticalTextAlign: 41,
          horizontalTextAlign: 11,
          zeroOffset: 0,
          strokeWidth: 10,
          progressPadding: 0,
          type: "circle",
        },
      },
      options2: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 10,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: true,
        },
        progress: {
          color: "#67BB80",
          backgroundColor: "#919EAB",
          inverted: false,
        },
        layout: {
          height: 5,
          width: 190,
          verticalTextAlign: 40,
          horizontalTextAlign: 11,
          zeroOffset: 0,
          strokeWidth: 10,
          progressPadding: 0,
          type: "line",
        },
      },
      myData: {},
      printObj: {
        id: "printMe",
        popTitle: "good print",
        extraCss: "https://www.google.com,https://www.google.com",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
      },
    };
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  mounted() {
    this.shareLink = "https://ct.alolo.sa/";
    //  window.location.href.split("/result")[0];
    console.log(
      "window.location.href",
      window.location.href.split("/result")[0]
    );
    this.$axios
      .get(
        `v1/exam/result/${localStorage.getItem(
          "account_id"
        )}/${localStorage.getItem("exam_id")}`
      )
      .then((res) => {
        console.log("res", res.data.data);
        this.myData = res.data.data;
      });
  },
};
</script>
<style>
.img-style {
  background: #f2f6ff;
  border-radius: 12px;
  padding: 13px;
  width: 70px;
  height: 57px;
  display: flex;
}
.re-custom {
  padding: 10px 30px;
  width: fit-content !important;
  margin-left: 20px !important;
}
.pdf-btn {
  background: #386995;
  color: white;
  text-align: center;
  padding: 10px 0;
  border-radius: 16px;
  width: 124px;
  height: 46px;
  margin-left: 50px;
  box-shadow: 0px 7px #67bb80 !important;
}
.card-chart {
  background: linear-gradient(
    to right,
    rgba(41, 119, 186, 1),
    rgba(57, 73, 155, 1)
  );
  border-radius: 12px;
  padding: 15px 20px;
}
@media (max-width: 1024px) {
  .text-custom {
    font-size: 42px !important;
    width: 100%;
  }
  .container {
    max-width: 990px !important;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 760px !important;
  }
  .categories-custom {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card-chart {
    width: 45% !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
    /* margin: auto; */
  }
  .categories-sec-2 {
    width: 95% !important;
    margin: auto;
    margin-bottom: 20px !important;
  }
  .categories-sec-2-big {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 630px !important;
  }
  .categories-custom {
    display: flex;
    flex-direction: column !important;
  }
  .card-chart {
    margin: auto !important;
    margin-bottom: 20px !important;
    width: 65% !important;
  }
  .text-custom {
    font-size: 45px !important;
    width: 92% !important;
  }
  .container {
    max-width: 680px !important;
  }
  .info {
    padding: 0 20px;
  }
}
@media (max-width: 640px) {
  .container {
    max-width: 450px !important;
  }
  .img-style {
    /* width: 253px; */
    margin-left: auto;
    margin-bottom: 20px;
  }
  .card-chart {
    width: 100% !important;
  }
  .text-custom {
    font-size: 30px !important;
  }
}
</style>
